<template>
  <div class="card">
    <div class="card-title">银行卡用户身份认证及领用佣金</div>
    <div class="owner">持卡人：{{owner}}</div>
    <div class="card-content">
      <div class="bank-name">
        <!-- <img :src="zhongguo" class="card-img">  -->
        <span>{{issueBank}}</span>
      </div>
      <div class="bank-desc">储蓄卡</div>
      <!-- <div class="card-img">{{ issueBank }}</div> -->
      <div class="card-no">{{ cardNo }}</div>
    </div>
  </div>
</template>

<script>
import { cardFilter } from '@/filter/filter'
import { getUserBankByNo } from '@/utils/api'
import common from '@/utils/common'
import {uwStatisticAction} from "../../utils/util";
export default {
  data() {
    return {
      zhongxin: require('../../assets/img/mine/zhongxin.png'),
      zhongguo: require('@/assets/img/mine/zhongguo.png'),
      cardNo: '',
      issueBank: '',
      owner: ''
    }
  },
  filters: {
    cardFilter
  },
  methods: {
    getCardInfo(){
      getUserBankByNo({
        uniqueNo: common.getItem('uniqueNo')
      }).then(res=>{
        if(res.code == 200){
          if(res.data && res.data.businessCode == 200){
            this.cardNo = res.data.data.bankCardNo
            this.issueBank = res.data.data.issueBank
            this.owner = res.data.data.name
          } else {
            this.cardNo = '您暂未绑定银行卡'
            this.issueBank = ''
          }
        } else {
          this.cardNo = '您暂未绑定银行卡'
          this.issueBank = ''
        }
      })
    }
  },
  mounted() {
    this.getCardInfo()
    uwStatisticAction('/card','我的银行卡')
  }
}
</script>

<style lang="less" scoped>
.card{
  background: #F7F8F9;
  height: 100%;
  font-size: 0.32rem;
  .card-title{
    padding: 0.2rem 0.3rem 0.12rem;
    color: #999;
    font-size: 0.26rem;
  }
  .owner{
    color: #121212;
    font-size: 0.32rem;
    margin: 0 0.3rem 0.24rem;
  }
  .card-content{
    color: #fff;
    margin: 0 auto;
    padding: 0.45rem;
    width: 6.9rem;
    height: 2.86rem;
    background: url("../../assets/img/mine/bank_card.png") no-repeat;
      background-size: 100% 100%;
    .card-img{
      width: 0.5rem;
    }
    .bank-name{
     display: flex;
     align-items: center;
     span{
       margin-left: 0.1rem;
     }
    }
    .bank-desc{
      font-size: 0.26rem;
      opacity: 0.6;
      padding-left: 0.1rem;
    }
    .card-no{
      font-size: 0.6rem;
      text-align: center;
      margin-top: 0.4rem;
    }
  }

}
</style>
